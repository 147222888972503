// components
import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading } from '@chakra-ui/layout';

// hooks
import useAppLanguage from '../../hooks/useAppLanguage';
import { useColorScheme } from '../../theme/chakra/colors';
import { CARD_RADIUS, INPUT_RADIUS, PADDING_INPUT } from '../../theme/chakra/layout';
import { INPUT_FONT } from '../../theme/chakra/typography';
import { ExternalLink } from '../Links/index';

interface OpaqueCirclesBackgroundProps {
  color: string;
}
const OpaqueCirclesBackground = ({ color }: OpaqueCirclesBackgroundProps) => (
  <Box h="130px" w="130px" position="absolute" top="0" left="0" overflow="hidden">
    {/* outer circle */}
    <Box
      h="130px"
      w="130px"
      borderRadius="50%"
      opacity="0.1"
      bgColor={color}
      position="absolute"
      top="0"
      left="0"
      transform="translate(-45%, -40%)"
    />
    {/* inner circle */}
    <Box
      h="65px"
      w="65px"
      borderRadius="50%"
      opacity="0.1"
      bgColor={color}
      position="absolute"
      top="0"
      left="0"
      transform="translate(-45%, -40%)"
    />
  </Box>
);

interface Props {
  borderColor?: 'blue' | 'yellow';
  actionType?: 'button' | 'link';
  action: ((flag: boolean) => void) | string;
  title?: string;
  main?: JSX.Element | string;
}

export default function Card({ borderColor = 'blue', action, actionType = 'button', title, main }: Props) {
  const btnColors = useColorScheme('makePrimaryButtonColors');
  const cardColors = useColorScheme('makeCardColors');
  const { translate } = useAppLanguage();

  return (
    <Flex
      w="100%"
      flex="flex"
      flexDir="column"
      justifyContent="space-between"
      gap="4"
      border="solid thin"
      padding={PADDING_INPUT}
      borderRadius={CARD_RADIUS}
      borderTop={`solid 10px`}
      borderColor={borderColor === 'yellow' ? cardColors.yellow : cardColors.border}
      borderTopColor={cardColors[borderColor]}
      position="relative"
    >
      {borderColor === 'yellow' && <OpaqueCirclesBackground color={cardColors.yellowOpaqueBackground as string} />}

      {/* <Box flex='flex' flexDir='column' justifyContent='space-between'> */}
      <Box>
        <Heading as="h4" size="md">
          {title}
        </Heading>
        <Box mt="4" textAlign="left">
          {main}
        </Box>
      </Box>
      {actionType === 'button' && action instanceof Function && (
        <Button
          colorScheme={btnColors.bg}
          variant="solid"
          w="100%"
          borderRadius={INPUT_RADIUS}
          padding={PADDING_INPUT}
          fontSize={INPUT_FONT}
          fontWeight="medium"
          {...btnColors}
          onClick={action}
        >
          {translate('Continue')}
        </Button>
      )}
      {actionType === 'link' && (action as string) && (
        <ExternalLink text={translate('Learn more')} href={action as string} />
      )}
    </Flex>
  );
}
