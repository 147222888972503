'use client';

import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import { PADDING_HALF } from '../../theme/chakra/layout';
import { SUBTITLE_FONT } from '../../theme/chakra/typography';

interface FormSubtitleProps extends React.PropsWithChildren {
  title?: string;
}

/**
 * Text to display, generally below the FormHeading.
 */
const FormSubtitle: React.FC<FormSubtitleProps> = ({
  children,
  /** @type {React.Children} */ title,
  /** @type {string} text to display, overridden by children */
  ...props /** rest of props, forwarded to Chakra <Box> */
}) => {
  return (
    <Box w="100%" paddingTop={PADDING_HALF} fontSize={SUBTITLE_FONT} {...props}>
      {/* as='div' is critical as it defaults to a <p> tag which cannot have descendants */}
      <Text as="div">{children ? children : title}</Text>
    </Box>
  );
};

export default FormSubtitle;
