'use client';

import { Heading } from '@chakra-ui/react';
import React from 'react';

import { MEDIUM_FONT, TITLE_FONT } from '../../theme/chakra/typography';

interface FormHeadingProps extends React.PropsWithChildren {
  title?: string;
  mono?: boolean;
}

/**
 * Form Heading to display, at top of form. Pairs with FormFooter.
 */
const FormHeading: React.FC<FormHeadingProps> = ({
  /** @type {React.Children} */
  children,
  /** @type {string} text to display, overridden by children */
  title,
  /** @type {boolean} whether to use monospaced font */
  mono = false,
  ...props /** rest of props, forwarded to Chakra <Heading> */
}) => {
  const fontOptions = mono
    ? { fontSize: MEDIUM_FONT, fontFamily: 'mono' }
    : { fontSize: TITLE_FONT, fontFamily: 'heading' };
  return (
    <Heading w="100%" as="h1" {...fontOptions} size="lg" {...props}>
      {children ? children : title}
    </Heading>
  );
};

export default FormHeading;
