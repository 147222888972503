'use client';

// components
import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { useRouter } from 'next/navigation';
import { TFunction, Trans } from 'react-i18next';

// hooks
import useAppLanguage from '../../hooks/useAppLanguage';
import { PADDING } from '../../theme/chakra/layout';
// utils
import { stripTrailingSlash } from '../../utils/utils';
import Card from '../Parts/Card';
import FormHeading from '../Parts/FormHeading';
import FormSubtitle from '../Parts/FormSubtitle';

const ZENDESK_HELP_URL = process.env.NEXT_PUBLIC_ZENDESK_HELP_URL ?? '';
const SSO_URL = process.env.NEXT_PUBLIC_WS_AUTH_URL ?? '';
const WS_MARKETPLACE_URL = process.env.NEXT_PUBLIC_WS_MARKETPLACE_URL ?? 'https://wonderschool.com';
const ONBOARD_URL = process.env.NEXT_PUBLIC_WS_ONBOARD_URL ?? 'https://onboarding.wonderschool.com';

const PROVIDER_SUPPLY_STATUS = {
  IS_NEW_SUPPLY: true,
  IS_NOT_NEW_SUPPLY: false,
};

export default function Onboarding() {
  const { translate, currentLanguage } = useAppLanguage();
  const router = useRouter();

  const handleBasicRedirect = (url: string) => {
    router.push(url);
    return null;
  };

  const makeProviderLink = (providerSupplyKey: typeof PROVIDER_SUPPLY_STATUS[keyof typeof PROVIDER_SUPPLY_STATUS]) => {
    const data = {
      ret: ONBOARD_URL,
      // rtn: check what the key is, but pass the self-serve onboard URL
      rts: {
        mode: 'signup',
        type: 'director',
        newSupply: providerSupplyKey,
      },
    };
    const encoded = Buffer.from(JSON.stringify(data)).toString('base64');
    // handle setting data flag....
    const url = `${stripTrailingSlash(SSO_URL)}/signup#${encoded}`;
    // redirect to SSO
    handleBasicRedirect(url);
  };

  const makeParentLink = () => {
    const data = {
      ret: `${stripTrailingSlash(WS_MARKETPLACE_URL)}/auth-return`,
      rts: {
        source: 'choose_role_modal',
        type: 'parent',
        mode: 'signup',
      },
      lang: currentLanguage?.code ?? 'en',
    };
    const encoded = Buffer.from(JSON.stringify(data)).toString('base64');
    // handle setting data flag....
    const url = `${stripTrailingSlash(SSO_URL)}/signup#${encoded}`;
    // redirect to SSO
    handleBasicRedirect(url);
  };

  return (
    <Flex justifyContent="center">
      <Box
        marginTop={{
          base: '4', // unique for narrow viewport
          sm: '0',
        }}
        padding={PADDING}
        minWidth={{
          md: '2xl',
        }}
      >
        <Box textAlign="center" mb="12">
          <FormHeading>{translate('Welcome to Wonderschool!')}</FormHeading>
          <FormSubtitle>
            <Text>{translate('Start by telling us about you.')}</Text>
          </FormSubtitle>
        </Box>

        <Stack direction={['column', 'row']} spacing="24px">
          <Card
            title={translate('Existing Provider')}
            main={translate('I want to use Wonderschool for my existing child care program.')}
            action={() => makeProviderLink(PROVIDER_SUPPLY_STATUS.IS_NOT_NEW_SUPPLY)}
          />
          <Card
            title={translate('New Provider')}
            main={translate('I want to start a brand new child care program with the help of Wonderschool.')}
            action={() => makeProviderLink(PROVIDER_SUPPLY_STATUS.IS_NEW_SUPPLY)}
          />
          <Card
            title={translate('New Family')}
            main={translate(
              'I am a parent or guardian and I want to search for a child care program using Wonderschool.'
            )}
            action={() => makeParentLink()}
          />
          <Card
            title={translate('Enrolled Family')}
            main={<EnrolledFamiliesMainContent t={translate} />}
            borderColor="yellow"
            actionType="link"
            action={`${stripTrailingSlash(ZENDESK_HELP_URL)}/11493089639703`}
          />
          <Card
            title={translate('Staff')}
            main={<StaffMainContent t={translate} />}
            borderColor="yellow"
            actionType="link"
            action={`${stripTrailingSlash(ZENDESK_HELP_URL)}/11493417367959`}
          />
        </Stack>
      </Box>
    </Flex>
  );
}

function EnrolledFamiliesMainContent({ t }: { t: TFunction }) {
  return (
    <Stack dir="column" gap="2">
      <Text>{t('I have already enrolled my child in a Wonderschool program.')}</Text>
      <div>
        <Trans
          t={t}
          i18nKey={
            "Please join with a provider invitation. Contact your provider if you don't see an invitation in your inbox."
          }
        >
          Please join with a <strong>provider invitation</strong>. Contact your provider if you don&apos;t see an
          invitation in your inbox.
        </Trans>
      </div>
    </Stack>
  );
}
function StaffMainContent({ t }: { t: TFunction }) {
  return (
    <div>
      <Trans
        t={t}
        i18nKey={
          "Please join with a provider invitation. Contact your provider if you don't see an invitation in your inbox."
        }
      >
        Please join with a <strong>provider invitation</strong>. Contact your provider if you don&apos;t see an
        invitation in your inbox.
      </Trans>
    </div>
  );
}
